<template>
  <div class="pa-8 toolbar-container">
    <v-row align="center">
      <v-col cols="4">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.date_range"
              label="対象月"
              color="#2F80ED"
              class="select"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              hide-details
            >
              <template v-slot:prepend>
                月
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filter.date_range"
            @input="menu = false"
            type="month"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-select
          class="select"
          color="#2F80ED"
          placeholder="対象従業員"
          outlined
          dense
          hide-details
          :items="allUsers"
          v-model="filter.user_id"
          item-text="full_name"
          item-value="id"
        >
          <template v-slot:prepend>
            従業員
          </template>
        </v-select>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn
          color="#2F80ED"
          min-width="13.6rem"
          depressed
          class="btn-search"
          @click="search"
        >
          <v-icon left color="white">
            mdi-magnify
          </v-icon>
          計算 & 表示
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="auto">
        <v-btn
          color="#828282"
          depressed
          text
          class="d-inline"
          @click="toggle()"
        >
          詳細条件
          <v-icon color="#828282" size="28" right
            >mdi-chevron-{{ expanded ? 'up' : 'down' }}</v-icon
          >
        </v-btn>

        <div class="divider d-inline"></div>
      </v-col>

      <v-col cols="auto" class="flex-grow-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row v-if="expanded">
      <v-col cols="2" align-self="center" class="text-right">
        WTタイプ
      </v-col>
      <v-col cols="10">
        <v-row>
          <template v-for="type in clockTypes">
            <v-col cols="auto" :key="`type-${type.id}`">
              <v-checkbox
                class="mt-0"
                v-model="filter.clockTypes"
                :label="type.name"
                :value="type.id"
                hide-details
              ></v-checkbox>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col cols="2" align-self="center" class="text-right">
        WTタグ
      </v-col>
      <v-col cols="10">
        <v-row>
          <template v-for="tag in tags">
            <v-col cols="auto" :key="`tag-${tag.id}`">
              <v-checkbox
                class="mt-0"
                v-model="filter.tags"
                :label="tag.name"
                :value="tag.id"
                hide-details
              ></v-checkbox>
            </v-col>
          </template> </v-row
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from '@/plugins/dayjs'
export default {
  name: 'Toolbar',
  data() {
    return {
      dayjs: dayjs,
      expanded: false,
      clockTypes: [
        { id: 0, name: '現場勤務（リ）' },
        { id: 1, name: '現場勤務（個）' },
        { id: 2, name: '運転・調整' },
        { id: 3, name: '参加' },
        { id: 4, name: 'タスク' },
        { id: 5, name: 'その他' }
      ],
      tags: [
        { id: 1, name: '遅刻' },
        { id: 2, name: '早退' },
        { id: 3, name: 'その他' }
      ],
      filter: {
        date_range: null,
        user_id: null,
        clockTypes: [],
        tags: [],
        sites: [],
        tasks: []
      },
      menu: false,
      tasks: []
    }
  },

  methods: {
    toggle() {
      this.expanded = !this.expanded
      this.$emit('wt-toolbar:toggle', this.expanded)
    },
    removeSites(item) {
      const index = this.filter.sites.indexOf(item.id)
      if (index >= 0) this.filter.sites.splice(index, 1)
      this.filterTasks()
    },
    removeTasks(item) {
      const index = this.filter.tasks.indexOf(item.id)
      if (index >= 0) this.filter.tasks.splice(index, 1)
    },
    filterTasks() {
      this.tasks = []
      this.tasks = this.allTasks.filter(item => {
        return this.filter.sites.includes(item.site?.id)
      })
    },
    search() {
      this.$emit('filter', JSON.parse(JSON.stringify(this.filter)))
    },
    setCurrentMonth() {
      let today = dayjs()
      if (today.date() >= 21) {
        this.filter.date_range = today.add(1, 'month').format('YYYY-MM')
        return
      }
      this.filter.date_range = today.format('YYYY-MM')
    }
  },

  computed: {
    allUsers() {
      return this.$store.getters.allUsersList
    },
    allSites() {
      let sites = this.$store.getters.allSites
      return sites.map(site => {
        return { ...site, siteName: site.name + ' ' + site.furigana_name }
      })
    },
    allTasks() {
      return this.$store.getters.allTasks
    }
  },

  created() {
    this.$store.dispatch('DROPDOWN_USER_LIST', { })
    this.tasks = this.allTasks
    this.setCurrentMonth()
    this.$emit('filter', JSON.parse(JSON.stringify(this.filter)))
  }
}
</script>

<style src="./Toolbar.scss" lang="scss" scoped></style>
