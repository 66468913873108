var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold mb-0"
  }, [_vm._v("勤怠一覧")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Toolbar', {
    on: {
      "filter": _vm.updateFilter
    }
  })], 1), _vm.operationalHour ? [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('h2', {
    staticClass: "font-weight-bold black--text"
  }, [_vm._v(" 総労働時間 " + _vm._s(_vm.operationalHour.operational_hours) + " 時間 ")])]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('h3', {
    staticClass: "font-weight-medium black--text",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.operationalHour.comments))])])] : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.worktimes,
      "total-records": _vm.worktimePagination.records_total,
      "number-of-pages": _vm.worktimePagination.total_pages,
      "items-per-page": _vm.itemsPerPage,
      "options": _vm.options,
      "footer": false,
      "loading": _vm.loading
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "text-wtid",
          on: {
            "click": function click($event) {
              return _vm.goToIndividual(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.id) + " ")])];
      }
    }, {
      key: "item.task_details.id",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-wtid",
          on: {
            "click": function click($event) {
              return _vm.goToTaskDetail(item.task_details.id);
            }
          }
        }, [_vm._v(" " + _vm._s(item.task_details.id) + " ")])];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(_vm.clockType(item.type_id)))])];
      }
    }, {
      key: "item.worktime_tag",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.tags.map(function (x) {
          return x.name;
        }).join(', ')) + " ")];
      }
    }, {
      key: "item.memo",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.memo ? _c('div', [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "size": "30"
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" $chat ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(item.memo))])])], 1) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "top",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mt-2 mb-4"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "text-heading-2 font-weight-medium",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" " + _vm._s(_vm.getDateRange(_vm.filter.date_range)) + " ")]), _c('v-col', {
          staticClass: "ml-4 text-heading-3 ",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 全 " + _vm._s(_vm.worktimePagination.records_total) + " 件 あります。 ")]), _c('v-col', {
          staticClass: "flex-grow-1 text-right",
          attrs: {
            "cols": "auto"
          }
        }, [_vm.worktimePagination.records_total > 0 ? _c('v-btn', {
          attrs: {
            "text": "",
            "color": "#4F55A7",
            "max-height": "24"
          },
          on: {
            "click": _vm.exportWorktime
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "size": "16"
          }
        }, [_vm._v("$csv")]), _vm._v(" この条件でCSV出力 ")], 1) : _vm._e()], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "btn-new",
          attrs: {
            "height": "30",
            "color": "#CFD3FE",
            "depressed": "",
            "min-width": "100",
            "to": "/work-time/create"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-plus ")]), _vm._v(" 勤怠を追加 ")], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }