<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h3 class="page-title font-weight-bold mb-0">勤怠一覧</h3>
        </v-col>

        <v-col cols="12">
          <Toolbar @filter="updateFilter"></Toolbar>
        </v-col>

        <template v-if="operationalHour">
          <v-col cols="3">
            <h2 class="font-weight-bold black--text">
              総労働時間 {{ operationalHour.operational_hours }} 時間
            </h2>
          </v-col>

          <v-col cols="9">
            <h3
              class="font-weight-medium black--text"
              style="white-space: pre-wrap"
            >{{ operationalHour.comments }}</h3>
          </v-col>
        </template>

        <v-col cols="12">
          <Table
            :headers="headers"
            :items="worktimes"
            :total-records="worktimePagination.records_total"
            :number-of-pages="worktimePagination.total_pages"
            :items-per-page="itemsPerPage"
            :options.sync="options"
            :footer="false"
            :loading="loading"
          >
            <template v-slot:item.id="{ item }">
              <div class="text-wtid" @click="goToIndividual(item)">
                {{ item.id }}
              </div>
            </template>

            <template v-slot:item.task_details.id="{ item }">
              <div
                class="text-wtid"
                @click="goToTaskDetail(item.task_details.id)"
              >
                {{ item.task_details.id }}
              </div>
            </template>

            <template v-slot:item.type="{ item }">
              <div>{{ clockType(item.type_id) }}</div>
            </template>

            <template v-slot:item.worktime_tag="{ item }">
              {{ item.tags.map(x => x.name).join(', ') }}
            </template>

            <template v-slot:item.memo="{ item }">
              <div v-if="item.memo">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="30" v-bind="attrs" v-on="on">
                      $chat
                    </v-icon>
                  </template>
                  <span>{{ item.memo }}</span>
                </v-tooltip>
              </div>
              <div v-else>-</div>
            </template>

            <template v-slot:top>
              <div class="mt-2 mb-4">
                <v-row align="center">
                  <v-col cols="auto" class="text-heading-2 font-weight-medium">
                    {{ getDateRange(filter.date_range) }}
                    <!-- {{ dateFormat(filter.date_range) }}（21日～翌20日） -->
                  </v-col>
                  <v-col cols="auto" class="ml-4 text-heading-3 ">
                    全 {{ worktimePagination.records_total }} 件 あります。
                  </v-col>

                  <v-col cols="auto" class="flex-grow-1 text-right">
                    <v-btn
                      text
                      color="#4F55A7"
                      max-height="24"
                      @click="exportWorktime"
                      v-if="worktimePagination.records_total > 0"
                    >
                      <v-icon size="16" class="mr-2">$csv</v-icon>
                      この条件でCSV出力
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      height="30"
                      class="btn-new"
                      color="#CFD3FE"
                      depressed
                      min-width="100"
                      to="/work-time/create"
                    >
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      勤怠を追加
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </template>
          </Table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import Toolbar from '@/components/admin/partials/WorkTime/Toolbar'
import dayjs from 'dayjs'
import axios from 'axios'
export default {
  name: 'List',
  components: { Toolbar, Table },
  data() {
    return {
      headers: [
        { text: '勤怠管理', align: 'center', value: 'task_details.date' },
        { text: 'WTID', align: 'center', value: 'id', sortable: false },
        {
          text: '従業員',
          align: 'center',
          value: 'user_details.full_name',
          sortable: false
        },
        {
          text: 'タスクID',
          align: 'center',
          value: 'task_details.id',
          sortable: false
        },
        {
          text: '顧客名',
          align: 'center',
          value: 'task_details.customer.name',
          sortable: false
        },
        {
          text: '現場名',
          align: 'center',
          value: 'task_details.site.name',
          sortable: false
        },
        {
          text: 'サービス',
          align: 'center',
          value: 'task_details.service.name',
          sortable: false
        },
        { text: 'タイプ', align: 'center', value: 'type', sortable: false },
        { text: 'IN', align: 'center', value: 'clock_in', sortable: false },
        { text: 'OUT', align: 'center', value: 'clock_out', sortable: false },
        {
          text: '休憩',
          align: 'center',
          value: 'rest_hours_total',
          sortable: false
        },
        {
          text: 'タグ',
          align: 'center',
          value: 'worktime_tag',
          sortable: false
        },
        { text: 'メモ', align: 'center', value: 'memo', sortable: false },
        {
          text: '昼労働計',
          align: 'center',
          value: 'work_hours_day',
          sortable: false
        },
        {
          text: '夜労働計',
          align: 'center',
          value: 'work_hours_night',
          sortable: false
        },
        {
          text: '昼休計',
          align: 'center',
          value: 'rest_hours_day',
          sortable: false
        },
        {
          text: '夜休計',
          align: 'center',
          value: 'rest_hours_night',
          sortable: false
        }
      ],
      filter: null,
      loading: true,
      itemsPerPage: 25,
      numberOfPages: null,
      totalRecords: 0,
      options: {}
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.filter)
      },
      deep: true
    }
  },

  computed: {
    operationalHour() {
      return this.$store.getters.operationalHour
    },
    worktimes() {
      return this.$store.getters.allWorktimes
    },
    worktimePagination() {
      return this.$store.getters.worktimePagination
    }
  },

  methods: {
    async getDataFromApi(filter = {}) {
      this.loading = true
      filter.paginate = true
      filter.page = this.options.page
      filter.sortBy = this.options.sortBy[0] ?? 'true'
      filter.sortDesc = this.options.sortDesc[0] ?? 'true'
      await this.$store.dispatch(
        'WORKTIME_OPERATIONAL_HOURS_GET',
        `${this.filter?.date_range}-01`
      )
      this.$store
        .dispatch('WORKTIMES', filter)
        .finally(() => (this.loading = false))
    },

    updateFilter(val) {
      this.filter = val
      let merged = { ...this.options, ...val }
      this.options = merged
    },
    getDateRange(date) {
      let previousMonth = dayjs(date)
        .subtract(1, 'month')
        .format('M月21日')
      return previousMonth + '〜' + dayjs(date).format('M月20日')
      // return dayjs(date).format('YYYY年M月分')
    },
    clockType(type_id) {
      let type = ''
      switch (type_id) {
        case 0:
          type = '現場勤務（リ）'
          break
        case 1:
          type = '現場勤務（個）'
          break
        case 2:
          type = '運転・調整'
          break
        case 3:
          type = '参加'
          break
        case 4:
          type = 'タスク'
          break
        case 5:
          type = 'その他'
          break

        default:
          break
      }
      return type
    },
    goToIndividual(item) {
      this.$router.push(
        '/work-time/' + item.task_details.date + '/' + item.user_id
      )
    },
    goToTaskDetail(id) {
      this.$router.push('/task/view/' + id)
    },
    exportWorktime() {
      delete this.filter.paginate
      delete this.filter.page
      axios
        .get('worktimes/export', {
          params: this.filter,
          responseType: 'arraybuffer'
        })
        .then(response => {
          let file_name = ''
          let time = dayjs().format('YYYYMMDDHHmm')
          let month = dayjs(this.filter.date_range).format('M')
          file_name += time + '_勤怠一覧_'
          let user_name = null
          if (this.filter.user_id) {
            user_name =
              this.$store.getters.allUsersList.filter(
                user => user.id == this.filter.user_id
              )[0].last_name + '_'
            file_name += user_name
          }
          file_name += month + '月.xlsx'

          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', file_name)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped></style>
